import { fetchGet, fetchDelete, fetchPost, fetchGetMess, fetchPostPic, fetchDeletePic, fetchPut } from '../index';
import downloadFile from '@/utils/claim/downloadFile';
import axios from 'axios';

//生成SICCO Checing Deatail的列表
export const getSICCOChecingDetailList = (params = {}): Promise<any> => {
  // return fetchGet('/claimapi/dealer/doc/save', {params})
  return fetchPost('/claimapi/checking/page', params);
};

//获取delay call back rule history列表
export const getDelayCallBackRuleHistory = (): Promise<any> => {
  return fetchGet(`/claimapi/call-back-rule/search`);
};
//获取activePeriod列表
export const getPeriody = (params = { type: '' }): Promise<any> => {
  return fetchGet(`/claimapi/callback/rule/activePeriod/${params.type}`, params);
};
//Add操作
export const addCallBackRule = (params = {}): Promise<any> => {
  return fetchPost(`/claimapi/call-back-rule/add-rule`, params);
};

//Close操作
export const closeRuleHistory = (params = { id: '' }): Promise<any> => {
  return fetchPost(`/claimapi/callback/rule/close/${params.id}`, params);
};
//生成region列表
export const getRegionLst = (params = { bu: '' }): Promise<any> => {
  return fetchPost(`/claimapi/common/regionQuery?bu=${params.bu}`, params);
};
//获取bu下拉框
export const getBuListNew = (): Promise<any> => {
  return fetchGet('/usapi/noAuth/bu/list');
};
// 获取省份城市 限牌城市
export const getAreasDate = () => {
  return fetchGet('/dapi/noAuth/dealer/provinceCity');
  // return fetchGet('/mdapi/areas');
};
//Edit
export const editSetupList = (params = {}): Promise<any> => {
  return fetchPut('/claimapi/call-back-rule/edit', params);
};
//删除
export const deleteSetupList = (params = {}): Promise<any> => {
  return fetchDelete('/claimapi/call-back-rule/delete', params);
};
//view
export const setupListView = (params = { id: '' }): Promise<any> => {
  return fetchGet(`/claimapi/call-back-rule/view/${params.id}`, params);
};
