
import { defineComponent, ref, reactive, toRefs, nextTick, onMounted } from 'vue';
import { message } from 'ant-design-vue';
import { Modal } from 'ant-design-vue';
import moment, { Moment } from 'moment';
// import EditEnquiry from "@/views/Claim/EnquiryProgram/EditEnquiryProgram";
import {
  getDelayCallBackRuleHistory,
  getPeriody,
  addCallBackRule,
  closeRuleHistory,
  getRegionLst,
  getBuListNew,
  getAreasDate,
  deleteSetupList,
  editSetupList,
  setupListView
} from '@/API/checking/delayCallBackRuleSetup';
import { AFCandLCApproveTableData } from '@/views/Payment/Mgmt/types';

const tableWidth = window.innerWidth - 350;
// const tableWidth = window.innerWidth
const columns = reactive([
  {
    title: 'Call Back Object',
    align: 'left',
    dataIndex: 'callBackType',
    width: 150
  },
  {
    title: 'Call Back Deadline',
    align: 'left',
    dataIndex: 'deadline',
    width: 180,
    slots: { customRender: 'deadline' }
  },
  {
    title: 'VIN',
    dataIndex: 'vin',
    width: 180,
    align: 'left',
    slots: { customRender: 'vin' }
  },
  {
    title: 'Retail Period',
    dataIndex: 'retailPeriod',
    width: 240,
    slots: { customRender: 'retailPeriod' }
  },
  {
    title: 'Program Code',
    align: 'left',
    dataIndex: 'programCode',
    width: 150,
    slots: { customRender: 'programCode' }
  },
  {
    title: 'Dealer Code',
    align: 'left',
    dataIndex: 'dealerCode',
    width: 150,
    slots: { customRender: 'dealerCode' }
  },
  {
    title: 'Province',
    align: 'left',
    dataIndex: 'province',
    width: 150,
    slots: { customRender: 'province' }
  },
  {
    title: 'City',
    align: 'left',
    dataIndex: 'city',
    width: 150,
    slots: { customRender: 'city' }
  },
  {
    title: 'Business Unit',
    align: 'left',
    dataIndex: 'bu',
    width: 150,
    slots: { customRender: 'bu' }
  },
  {
    title: 'Region',
    align: 'left',
    dataIndex: 'region',
    width: 150,
    slots: { customRender: 'region' }
  },
  {
    title: 'Status',
    align: 'left',
    dataIndex: 'status',
    width: 120
    //slots: { customRender: 'status' }
  },
  { title: 'Open Date', align: 'left', dataIndex: 'openDate', width: 180, slots: { customRender: 'openDate' } },

  {
    title: 'Operation',
    align: 'left',
    fixed: 'right',
    dataIndex: 'checkResult',
    width: 240,
    slots: { customRender: 'operation' }
  }
]);
const columnsDealer = reactive([
  {
    title: 'Dealer Code',
    align: 'center',
    dataIndex: 'code',
    width: 120
  },
  {
    title: 'Dealer Name',
    align: 'center',
    dataIndex: 'name',
    width: 120
  }
]);
const columnsVin = reactive([
  {
    title: 'VIN',
    align: 'center',
    dataIndex: 'vin',
    width: 120
  }
]);
const columnsProgram = reactive([
  {
    title: 'Program Code',
    align: 'center',
    dataIndex: 'code',
    width: 120
  },
  {
    title: 'Program Name',
    align: 'center',
    dataIndex: 'name',
    width: 120
  }
]);
export default defineComponent({
  components: {},
  setup() {
    const tableHeight = ref();
    const searchCon = ref();
    const queryListData = ref<any[]>([]);
    const selectOptions = ref<any[]>([]);

    const formLst = reactive<any>({
      callBackType: '',
      bu: '',
      region: '',
      programCode: '',
      province: '',
      city: '',
      dealerCode: '',
      deadline: '',
      comments: '',
      vinNo: '',
      retailPeriod: [],
      periodFrom: '',
      periodTo: ''
    });

    const regionLst = ref<any>('');
    const regionAble = ref<any>(false);
    const businessUnitSelect = ref<any>([]);
    const provinceAble = ref<any>(true);
    const cityAble = ref<any>(false);
    const areasArr = ref<any>([]);
    const nowCityLst = ref<any>([]);
    const codeModalInf = ref<any>({
      isCover: false,
      codeModalTit: '',
      codeModalLstTit: ''
    });
    //显示的VIN数组
    const textNoArray = ref<any>([]);
    //输入框，用于控制焦点
    const input = ref();

    const queryParams = ref<any>({
      dealerCode: [],
      programCode: [],
      vinNo: []
    });
    const textContentNo = ref<string>('');
    const commentsLastValue = ref<any>('');
    const nowDate = new Date();
    const nowItemData = ref<any>({});
    const viewCove = ref<any>(false);
    const editCove = ref<any>(false);
    const deadline = ref<any>('');
    const dealerCove = ref<any>(false);
    const nowDealerLstItem = ref<any>({
      dealerCodeList: [],
      programCodeList: [],
      vinList: [],
      status: '',
      statusText: ''
    });

    nextTick(() => {
      (document.getElementsByClassName('table-box')[0] as any).style.height =
        window.innerHeight - 220 - searchCon.value.scrollHeight + 'px';
      const height = (document.getElementsByClassName('table-box')[0] as any).style.height;
      const heightNum = parseInt(height.substr(0, height.indexOf('px')));

      // tableHeight.value = heightNum - 110;
      tableHeight.value = heightNum - 100;
    });

    const rowSelection = reactive<{
      selectedRowKeys: [];
      selectedRowData: AFCandLCApproveTableData[];
      onChange: Function;
      columnWidth: string;
    }>({
      onChange: (selectedRowKeys: [], selectedRows: AFCandLCApproveTableData[]) => {
        rowSelection.selectedRowKeys = selectedRowKeys;
        rowSelection.selectedRowData = selectedRows;
      },
      selectedRowKeys: [],
      selectedRowData: [],
      columnWidth: '40px'
    });

    const dealerDisabled = ref<boolean>(false);
    const isDealerDisabled = () => {
      if (formLst.region || formLst.province) {
        dealerDisabled.value = true;
        formLst.dealerCode = '';
        queryParams.value.dealerCode = [];
      } else {
        dealerDisabled.value = false;
      }
    };

    const reset = () => {
      formLst.callBackType = '';
      formLst.bu = '';
      formLst.region = '';
      formLst.programCode = '';
      formLst.province = '';
      formLst.city = '';
      formLst.dealerCode = '';
      formLst.deadline = '';
      formLst.comments = '';
      formLst.vinNo = '';
      formLst.retailPeriod = [];
      formLst.periodFrom = '';
      formLst.periodTo = '';
    };

    const radioChange = () => {
      getPeriody({ type: formLst.callBackType }).then((res) => {
        selectOptions.value.length = 0;
        const periodTimeArr = ref<any>([]);
        if (res) {
          res.forEach((resItem: any, resIndex: any) => {
            periodTimeArr.value.push(resItem);
          });
        }

        selectOptions.value = periodTimeArr.value;
      });
      if (formLst.callBackType == 'Retail') {
        formLst.programCode = '';
        queryParams.value.programCode = [];
      }
      if (formLst.callBackType == 'Claim') {
        formLst.retailPeriod = '';
        formLst.periodFrom = '';
        formLst.periodTo = '';
      }
    };

    const disabledDate = (current: Moment) => {
      return current && current < moment().startOf('day');
    };
    const disabledPeriodDate = (date: Moment) => {
      return date >= moment().endOf('day');
    };
    const watchComments = (e: any) => {
      if (e.target.value && e.target.value.length > 200) {
        message.error('Exceed Limited Word.');
        formLst.comments = commentsLastValue.value;
      } else {
        commentsLastValue.value = e.target.value;
      }
    };

    const doGetListData = () => {
      getDelayCallBackRuleHistory().then((res) => {
        queryListData.value = res ? res : [];
      });
    };
    doGetListData();

    const handleView = (record: any) => {
      setupListView({ id: record.id }).then((res: any) => {
        nowItemData.value = res;
        viewCove.value = true;
      });
    };

    const handleEdit = (record: any) => {
      setupListView({ id: record.id }).then((res: any) => {
        deadline.value = res ? res.deadline : '';
        nowItemData.value = res;
        editCove.value = true;
      });
    };
    const handleDelete = (i: any) => {
      const params = { id: i.id };
      deleteSetupList({ params }).then((res) => {
        message.success('Success!');
        doGetListData();
      });
    };

    const handleDealerMore = (type: string, data: any) => {
      const params = ref<any>({});
      params.value.status = type;

      if (type === 'dealers') {
        params.value.statusText = 'Dealer';
        params.value.dealerCodeList = data;
      } else if (type === 'vin') {
        params.value.statusText = 'VIN';
        params.value.vinList = data.map((item: any) => ({ vin: item }));
      } else if (type === 'programs') {
        params.value.statusText = 'Program';
        params.value.programCodeList = data;
      }
      nowDealerLstItem.value = params.value;
      // viewCove.value = false;
      dealerCove.value = true;
    };

    const handleEditOk = () => {
      const params = {
        deadline: deadline.value,
        id: nowItemData.value.id
      };
      editSetupList(params).then((res) => {
        message.success('Success!');
        handleCancel();
        doGetListData();
      });
    };
    const handleAdd = () => {
      if (!formLst.callBackType) {
        message.error('Please select Call Back Object');
        return;
      }
      if (formLst.callBackType == 'Claim') {
        if (!formLst.bu) {
          message.error('Please select Business Unit');
          return;
        }

        if (!formLst.deadline) {
          message.error('Please select Deadline Date');
          return;
        }
        if (!formLst.comments) {
          message.error('Please select Comments');
          return;
        }
        const dealerArr = [];
        const vinArr = [];
        const programArr = [];
        if (formLst.dealerCode && formLst.dealerCode.trim()) {
          dealerArr.push(formLst.dealerCode);
        }
        if (formLst.vinNo && formLst.vinNo.trim()) {
          vinArr.push(formLst.vinNo);
        }
        if (formLst.programCode && formLst.programCode.trim()) {
          programArr.push(formLst.programCode);
        }
        if (formLst.retailPeriod.length > 0) {
          formLst.periodFrom = formLst.retailPeriod[0];
          formLst.periodTo = formLst.retailPeriod[1];
        }
        const params = {
          callBackType: formLst.callBackType,
          bu: formLst.bu,
          region: formLst.region,
          programCodeList:
            queryParams.value.programCode && queryParams.value.programCode.length > 0
              ? queryParams.value.programCode
              : programArr,
          province: formLst.province,
          city: formLst.city,
          dealerCodeList:
            queryParams.value.dealerCode && queryParams.value.dealerCode.length > 0
              ? queryParams.value.dealerCode
              : dealerArr,
          deadline: formLst.deadline,
          comments: formLst.comments,
          vinList: queryParams.value.vinNo && queryParams.value.vinNo.length > 0 ? queryParams.value.vinNo : vinArr,
          periodFrom: formLst.periodFrom,
          periodTo: formLst.periodTo
        };

        addCallBackRule(params).then((res) => {
          reset();
          queryParams.value.programCode = [];
          queryParams.value.dealerCode = [];
          queryParams.value.vinNo = [];
          doGetListData();
          message.success('Success!');
        });
      } else {
        if (!formLst.deadline) {
          message.error('Please select Deadline Date');
          return;
        }
        if (!formLst.comments) {
          message.error('Please select Comments');
          return;
        }
        const dealerArr = [];
        const vinArr = [];
        const programArr = [];
        if (formLst.dealerCode && formLst.dealerCode.trim()) {
          dealerArr.push(formLst.dealerCode);
        }
        if (formLst.vinNo && formLst.vinNo.trim()) {
          vinArr.push(formLst.vinNo);
        }
        if (formLst.programCode && formLst.programCode.trim()) {
          programArr.push(formLst.programCode);
        }
        if (formLst.retailPeriod.length > 0) {
          formLst.periodFrom = formLst.retailPeriod[0];
          formLst.periodTo = formLst.retailPeriod[1];
        }
        const params = {
          callBackType: formLst.callBackType,
          bu: formLst.bu,
          region: formLst.region,
          programCodeList:
            queryParams.value.programCode && queryParams.value.programCode.length > 0
              ? queryParams.value.programCode
              : programArr,
          province: formLst.province,
          city: formLst.city,
          dealerCodeList:
            queryParams.value.dealerCode && queryParams.value.dealerCode.length > 0
              ? queryParams.value.dealerCode
              : dealerArr,
          deadline: formLst.deadline,
          comments: formLst.comments,
          vinList: queryParams.value.vinNo && queryParams.value.vinNo.length > 0 ? queryParams.value.vinNo : vinArr,
          periodFrom: formLst.periodFrom,
          periodTo: formLst.periodTo
        };

        addCallBackRule(params).then((res) => {
          reset();
          queryParams.value.programCode = [];
          queryParams.value.dealerCode = [];
          queryParams.value.vinNo = [];
          doGetListData();
          message.success('Success!');
        });
      }
    };

    const judgeForm = () => {
      if (!formLst.bu) {
        formLst.region = '';
        window.setTimeout(() => {
          regionAble.value = false;
        }, 100);
      } else {
        if (formLst.dealerCode) {
          regionAble.value = false;
        } else {
          regionAble.value = true;
        }
      }
    };
    const dogetRegion = () => {
      getRegionLst({ bu: formLst.bu || '' }).then((res) => {
        formLst.region = '';
        regionLst.value = res;
      });
    };
    const doGetSelect = () => {
      getBuListNew().then((res) => {
        businessUnitSelect.value = res.filter((item: any) => {
          return item.parentId === null;
        });
      });
      getAreasDate().then((res) => {
        areasArr.value = res;
      });
    };
    doGetSelect();

    //空占位
    const batchPlaceholder = ref(['', '', '', '', '', '']);
    // 设置批量查询modal的占位div
    const setBatchPlaceholder = () => {
      if (textNoArray.value.length) {
        batchPlaceholder.value = [];
      } else {
        batchPlaceholder.value = ['', '', '', '', '', ''];
      }
    };
    const cleanEmptyEleArray = (array: Array<string>) => {
      console.log('array', array);
      if (array.length > 1) {
        const tempArray = array.filter((item) => item !== '');
        tempArray.length || tempArray.push('');

        return tempArray;
      } else {
        return array;
      }
    };
    // 设置批量查询modal的textNoArray
    const setBatchQueryTextNoArray = () => {
      console.log(863, textNoArray.value);
      if (codeModalInf.value.codeType === 'dealerCode') {
        textNoArray.value =
          cleanEmptyEleArray(queryParams.value.dealerCode)[0] === ''
            ? []
            : cleanEmptyEleArray(queryParams.value.dealerCode);
      } else if (codeModalInf.value.codeType === 'programCode') {
        textNoArray.value =
          cleanEmptyEleArray(queryParams.value.programCode)[0] === ''
            ? []
            : cleanEmptyEleArray(queryParams.value.programCode);
      } else {
        textNoArray.value =
          cleanEmptyEleArray(queryParams.value.vinNo)[0] === '' ? [] : cleanEmptyEleArray(queryParams.value.vinNo);
      }
    };
    const handleDealerCodeBtn = (codeType: any) => {
      if (codeType === 'dealerCode') {
        codeModalInf.value = {
          isCover: true,
          codeType: 'dealerCode',
          codeModalTit: 'Dealer Code',
          codeModalLstTit: 'Dealer Code'
        };
      } else if (codeType === 'programCode') {
        codeModalInf.value = {
          isCover: true,
          codeType: 'programCode',
          codeModalTit: 'Program Code',
          codeModalLstTit: 'Program Code'
        };
      } else {
        codeModalInf.value = {
          isCover: true,
          codeType: 'vinNo',
          codeModalTit: 'VIN',
          codeModalLstTit: 'VIN'
        };
      }

      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      const dom = document.getElementsByClassName('list-content')[0];
      nextTick(() => {
        dom && dom.scrollTo(0, textNoArray.value.length * 36);
        input.value.focus();
      });
    };
    const judgeFormProvince = () => {
      if (!formLst.province) {
        formLst.city = '';
        window.setTimeout(() => {
          cityAble.value = false;
        }, 100);
      } else {
        areasArr.value.forEach((i: any) => {
          if (formLst.province === i.provinceEn) {
            nowCityLst.value = i.cityList;
          }
        });
        cityAble.value = true;
      }
    };

    // const judgeFormDealerCode = () => {
    //   if (formLst.callBackType == 'Claim') {
    //     if (formLst.dealerCode) {
    //       formLst.province = '';
    //       formLst.region = '';
    //       window.setTimeout(() => {
    //         cityAble.value = false;
    //         provinceAble.value = false;
    //         regionAble.value = false;
    //       }, 100);
    //     } else {
    //       cityAble.value = false;
    //       provinceAble.value = true;
    //       regionAble.value = formLst.bu ? true : false;
    //     }
    //   }
    // };

    const closeDealerCodeCover = () => {
      formLst.dealerCode = cleanEmptyEleArray(queryParams.value.dealerCode)[0];
      formLst.programCode = cleanEmptyEleArray(queryParams.value.programCode)[0];
      formLst.vinNo = cleanEmptyEleArray(queryParams.value.vinNo)[0];
      // if (codeModalInf.value.codeType === 'dealerCode') {
      //   judgeFormDealerCode();
      // }
    };
    const textChangEvent = () => {
      if (textContentNo.value.length < 5) {
        message.error('请从Excel中粘贴到文本框');
        textContentNo.value = '';
        return;
      }
      console.log(1049, textNoArray.value);
      textNoArray.value = textNoArray.value.concat(textContentNo.value.replace(/\r|\n|\s/g, ',').split(','));

      const dom = document.getElementsByClassName('list-content')[0];
      textContentNo.value = '';
      if (codeModalInf.value.codeType === 'dealerCode') {
        queryParams.value.dealerCode = [];
        queryParams.value.dealerCode = queryParams.value.dealerCode.concat(textNoArray.value);
      } else if (codeModalInf.value.codeType === 'programCode') {
        queryParams.value.programCode = [];
        queryParams.value.programCode = queryParams.value.programCode.concat(textNoArray.value);
      } else {
        queryParams.value.vinNo = [];
        queryParams.value.vinNo = queryParams.value.vinNo.concat(textNoArray.value);
      }
      setBatchPlaceholder();
      nextTick(() => {
        dom.scrollTo(0, dom.scrollHeight + 40);
        input.value.focus();
      });
    };
    // 清除批量查询
    const clearBatchQuery = () => {
      if (codeModalInf.value.codeType === 'dealerCode') {
        queryParams.value.dealerCode = [''];
      } else if (codeModalInf.value.codeType === 'programCode') {
        queryParams.value.programCode = [''];
      } else {
        queryParams.value.vinNo = [''];
      }
      setBatchQueryTextNoArray();
      setBatchPlaceholder();
      input.value.focus();
    };

    const handleCancel = () => {
      viewCove.value = false;
      editCove.value = false;
      dealerCove.value = false;
      nowItemData.value = {};
    };

    const handleDealerCancel = () => {
      dealerCove.value = false;
      nowDealerLstItem.value = {
        dealerCodeList: [],
        vinList: [],
        programCodeList: [],
        status: ''
      };
    };

    return {
      disabledDate,
      disabledPeriodDate,
      dealerDisabled,
      isDealerDisabled,
      handleAdd,
      selectOptions,
      radioChange,
      formLst,
      doGetListData,
      rowSelection,
      searchCon,
      tableHeight,
      queryListData,
      tableWidth,
      columns,
      judgeForm,
      judgeFormProvince,
      dogetRegion,
      areasArr,
      businessUnitSelect,
      provinceAble,
      cityAble,
      regionLst,
      regionAble,
      handleDealerCodeBtn,
      setBatchQueryTextNoArray,
      codeModalInf,
      nowDate,
      nowItemData,
      viewCove,
      editCove,
      deadline,
      dealerCove,
      nowDealerLstItem,
      textNoArray,
      nowCityLst,
      input,
      queryParams,
      closeDealerCodeCover,
      textContentNo,
      textChangEvent,
      batchPlaceholder,
      clearBatchQuery,
      watchComments,
      commentsLastValue,
      handleDealerMore,
      handleDealerCancel,
      handleCancel,
      handleEdit,
      handleView,
      handleEditOk,
      columnsDealer,
      columnsProgram,
      columnsVin,
      handleDelete,
      reset
    };
  }
});
